@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

html, body{
    width:100%;
    height:100%;
    margin:0;
    padding:0;
    font-family: 'Montserrat', sans-serif;
    color:#7a7a7a;
}

// CORES 

$main-color:#f9a100;
$darkmain-color:#900d12;
$lightmain-color:#ffb71a;
$blood:#7d1014;
$black:#252525;
$white:#FFFFFF;
$gray-base:#000;
$gray: #58595b;
$mediumgray:#7a7a7a;

$content-width-mobile: 100%;

// FONTES E TITULOS

@mixin title {
    font-style:normal;
    font-weight:100;
    font-size:2.6em;
	letter-spacing: 1px;
    line-height:50px;
}
@mixin subtitle {
    font-style:normal;
    font-weight:100;
    font-size:1.4em;
    line-height:36px;
    color:$main-color;
}
@mixin text {
    font-style:normal;
    font-weight:100;
    font-size:1em;
	line-height:30px; 
}

h1 {
    @include title;
}
h2 {
	font-size:1em;
}
span {
    @include subtitle;
}
p {
    @include text;
}

// CONFIGURAÇÕES

::selection {
    color:#FFF;
    background:$main-color;
}
.wow:first-child {
    visibility:hidden;
}
*,
*:before,
*:after{
    -webkit-box-sizing:inherit;
    -moz-box-sizing:inherit;
    box-sizing:inherit;
    text-decoration:none;
}
hr {
	background-color:$main-color;
	border:0;
	height:1px;
}
.full{
    height:100%;
    width:100%;
}
.full2{
    height:750px;
    width:100%;
}
.text-right {
	text-align:right;
}
.text-left {
	text-align:left;
}
.text-center {
	text-align:center;
}
.none {
    display:none;
}
.nopadding {
    padding:0 !important;
}
.margin-bottom {
	margin-bottom:40px;
}

.leiamais {
	text-decoration: none;
	color:$main-color;
	font-weight:bold;
	border-bottom:solid 2px $main-color;
	&:hover {
		border-bottom:0;
		border-top:solid 2px $main-color;
		transition-duration: .6s;
		cursor:pointer;
	}
}

.bg {
	background-size:cover;background-attachment:fixed;
}
.bghome {
	background-image: url(../img/bg-home.png) !important;
}
.bgequipe {
	background-image: url(../img/bg-equipe.png) !important;
}
.bgpub {
	background-image: url(../img/bg-pub.png) !important;
}
// ÚLTIMAS NOTÍCIAS
#publicacoes {
	.container {
		margin:25px auto;
		border-top:solid 1px $main-color;
		border-bottom:solid 1px $main-color;
	}
	p {
		line-height:22px;
	}
	h3, h2 {
		color:$main-color;
	}
	h3 {
		padding-bottom:15px;
	}
	img {
		width:100%;
		max-height:350px;
		-webkit-transition: .5s ease-in-out;
		transition: .5s ease-in-out;
		&:hover {
			opacity: .7;
			cursor:pointer;
		}
	}
}


// ESCRITORIO 
#escritorio {
	text-align:center;
	.container {
		border-bottom:solid 1px $main-color;
		border-top:solid 1px $main-color;
		margin-bottom:45px;
		margin-top:10px;
		padding-bottom:1px;
	}
	h1 {
		font-weight: bold;
	}
	p {
		text-align: justify;
	}
	.col-lg-3 {
		p {
			text-align: left;
			font-size:1.2em;
		}
	}
	img {
		margin:35px auto 15px auto !important;
	}
}

// BLOG 
#blog {
	.container {
		border-bottom:solid 1px $main-color;
		margin-bottom:45px;
	}
	.post {
		padding-bottom:40px;
		margin:20px auto;
		img {
			cursor:pointer;
			max-width:100%;
		}
	}
	h2 {
		color:$main-color;
		text-transform: uppercase;
	}
	.single-post {
		padding-bottom:40px;
		margin:20px auto;
		p {
			text-align: justify;
		}
		h2 {
			color:$main-color;
			font-size:1.4em;
			text-transform: uppercase
		}
		img {
			max-width:100%;
		}
	}
}

// TRABALHE CONOSCO 
#trabalhe-conosco {
    width:100%;
	background-image: url(../img/bg-trabalhe-conosco.png) !important;
	background-size:cover;
	padding-top:80px;
	padding-bottom:80px;
	h1 {
		color:$white;
		font-size:2em;
	}
}
// CONTATO 
#contato {
    width:100%;
	background-image: url(../img/bg-contato.png) !important;
	background-size:cover;
	padding-top:80px;
	padding-bottom:80px;
	h1 {
		color:$white;
		font-size:2em;
	}
}

input{   
	width:100%;
	height:30px;
	margin-bottom:20px;
	padding-bottom:10px;
	color:$gray !important;
	background-color: transparent;
	border:0;
	border-bottom:solid 1px $white;
	text-transform: uppercase;
	opacity: .9;
	font-size:.8em;
	letter-spacing: 1px;
	font-weight:bold;
	font-family:'Roboto' !important;
}
::placeholder {
	color:$white;
}

input[type=submit]{
	width:100px;
	height:30px;
	padding:5px;
	text-align: center;
	font-weight: bold;
	color:$main-color !important;
	background:$white;
	border:0;
	margin:10px auto;
	text-transform: uppercase;
	font-family:'Roboto' !important;
}
input[type=text]:hover, button:hover{ 
	opacity: .9;
}
textarea {
	width:100%;
	height:150px;
	background-color:transparent;
	border:0;
	border-bottom:solid 1px $white;
	font-family:'Roboto' !important;
	text-transform: uppercase;
	font-size:.8em;
	letter-spacing: 1px;
	font-weight:bold;
	color:$gray !important;
}

//EQUIPE
#equipe {
	background-color: $white;
	.container {
		margin:25px auto;
		border-top:solid 1px $main-color;
		border-bottom:solid 1px $main-color;
		h2 {
			font-size:1.2em;
			color:$main-color;
		}
		i{
			font-weight:bold !important;
			padding-left:5px;
			color:$main-color;
		}
		.search{
			width:90% !important;
			margin:10px auto !important;
			height:35px !important;
			padding:5px !important;
			background-color:transparent !important;
			border-radius:20px !important;
			border:solid 2px $main-color !important;
			display:inline;
		}
	}
}





// FOOTER

#mapa-home iframe {
	width:100%;
	height:450px;
	border:0;
}
#mapa iframe {
	width:100%;
	height:250px;
	border:0;
}

footer {
	width:100%;
	background-color:$lightmain-color;
	color:$white;
	padding-top:30px;
	bottom:0 !important;
	a{
		color:$white;
		font-weight:500;
		&:hover {
			color:$gray;
		}
	}
	.col-lg-4 {
		img {
			padding-top:50px;
			padding-bottom:50px;
		}
	}	
	.border {
		border-right:solid 1px #FFF;padding-right:30px;
	}
	i {
		color:$white;
		width:40px;
		height:40px;
		text-align:center;
		padding-top:5px;
		margin-right:5px;
		&:hover {
			color:$gray;
		}
	}
	.container {
		height:350px;
	}
}


// RESPONSIVE DESKTOP

@media (min-width:781px){
    .esconder-desktop{
        display:none;
    }
}

// RESPONSIVE MOBILE

@media (max-width:780px){
    .full, .full2{
        max-height:300px;
    }
    .esconder{
        display:none;
    }
    h1 {
        line-height:normal !important;
        text-align:center;
        font-size:3em;
    }
    span {
        line-height:normal !important;
        font-size:1.2em;
        display:block
    }
	
	.border {
		border:0 !important;
        margin:0 auto !important;
	}
	footer {
		text-align:center;
        min-height:650px;
        font-size:.9em;
        img {
			text-align:center;
			margin:25px auto;
			padding-top:50px;
		}
        .border {
            padding-right:0px;
        }
	}
    
	.nav-container {
	  max-width: 100% !important;
	  margin: 0 auto;
	}
      
}

// BG VIDEO

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y); // IE9 only
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}
@mixin background-size($size) {
 -webkit-background-size: $size;
    -moz-background-size: $size;
    -o-background-size: $size;
    background-size: $size ;
}
.fullscreen {
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  overflow:hidden;
  z-index:-100;  
    &::before {
        content:"";
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        z-index:1;
    }
}

.video {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
.home-content {
    width:100%;
    position:absolute;
    left:50%;
    top:60%;
    z-index:999;
	text-align:right;
    @include translate(-50%, -50%); 
    span {
		color:#FFFFFF !important;
        line-height:45px;
		text-decoration:none;
    }
	a {
		color:#FFFFFF !important;
		text-decoration:none;
		&:hover {
			border-bottom:solid 4px $main-color;
		}
	}
	h1 {
		color:$white;
		font-size:2.6em;
		line-height:50px;
	}
	i {
		color:$white;
		padding-top:300px;
	}
}

.home-content2 {
    width:100%;
    position:absolute;
    top:45%;
    z-index:999;
    text-align:right;
    color:$white;
    span {
        line-height:45px;
		text-decoration:none;
    }
	a {
		color:#FFFFFF !important;
		text-decoration:none;
		&:hover {
			border-bottom:solid 4px $main-color;
		}
	}
	h1 {
		color:$white;
		font-size:2.6em;
		line-height:50px;
	}
	i {
		color:$white;
		padding-top:300px;
	}
}

@media (min-aspect-ratio: 16/9) {
  .video {
    height:300%;
    top:-100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video {
    width:300%;
    left:-100%;
  }
}
@media (max-width: 767px) {
  .fullscreen {
    background-color:$black;
    @include background-size(cover);
  }
  .video {
    display: none;
  }
}


//MENU

// Navigation Variables
$content-width: 80%;
$content-width-mobile: 100%;
$breakpoint: 800px;
$nav-height: 110px;
$nav-background: $white;
$nav-font-color: $gray;
$link-hover-color: $main-color;

// Outer navigation wrapper
.navigation {
  height: $nav-height;
  background: $nav-background;
}

// Logo and branding
.brand {
  position: absolute;
  padding: 15px;
  float: left;
  line-height: $nav-height;
}

// Container with no padding for navbar
.nav-container {
  max-width: $content-width;
  margin: 0 auto;
}

// Navigation 
nav {
  float: right;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      float: left;
      position: relative;
      a,
      a:visited {
        display: block;
        padding: 0 20px;
        line-height: $nav-height;
        background: $nav-background;
        color: $nav-font-color;
        text-decoration: none;
        &:hover {
          color: $main-color;
        }
		  i {
			  color:$main-color;
		  }
      } // Dropdown list
      ul li {
        min-width: 230px;
        a {
          padding: 15px;
          line-height: 20px;
        }
      }
    }
  }
}

// Dropdown list binds to JS toggle event
.nav-dropdown {
  position: absolute;
  display: none;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

/* Mobile navigation */

// Binds to JS Toggle
.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: $nav-background;
  height: $nav-height;
  width: $nav-height;
}
@media only screen and (max-width: $breakpoint) {
  // Hamburger nav visible on mobile only
  .nav-mobile {
    display: block;
  }
  nav {
   width: 100%;
    padding: $nav-height 0 15px;
    ul {
      display: none;
      li {
        float: none;
        a {
          padding: 15px;
          line-height: 20px;
        }
        ul li a {
          padding-left: 30px;
        }
      }
    }
  }
  .nav-dropdown {
    position: static;
  }
}
@media screen and (min-width: $breakpoint) {
  .nav-list {
    display: block !important;
  }
	.brand {
		min-width:90%;
		max-width:91%;
	}
}
#nav-toggle {
  position: absolute;
  left: 55px;
  top: 22px;
  cursor: pointer;
  padding: 30px 5px 16px 0px;
  span,
  span:before,
  span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: $main-color;
    position: absolute;
    display: block;
    content: '';
    transition: all 300ms ease-in-out;
  }
  span:before {
    top: -10px;
  }
  span:after {
    bottom: -10px;
  }
  &.active span {
    background-color: transparent;
    &:before,
    &:after {
      top: 0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}










